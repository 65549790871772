import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import irth from "../images/irth.png"

const LandManagementSystem = () => (
  <Layout>
    <SEO title="Land Management Systems" />
    <div className="page-content">
      <div className="container">
        <h1 className="display-4 text-orange">Land Management Systems</h1>
        <div className="col-md-10 px-md-5 mb-4 mx-auto">
            <div className="text-center mb-3">
              <a
                href="https://www.irthsolutions.com/land-management/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  loading="lazy"
                  src={irth}
                  alt="Grooper"
                  width="400px"
                  className="img-fluid"
                />
              </a>
            </div>
          </div>
        <p>
        Irth's market-leading SaaS platform improves resilience and reduces risk in the sustainable delivery of essential services that millions of people and businesses rely on every day.
        </p>
        <p>
        Energy, utility, and telecom companies across the U.S. and Canada trust Irth for damage prevention, training, asset inspections, and land management solutions.
        </p>
        <div className="row my-5">
          <div className="col-md-6 py-2 px-md-5">
            <h3 className="h4">Efficiency</h3>
            <p>
            Powered by business intelligence, analytics, and geospatial data, our platform helps deliver the 360-degree situational awareness needed to proactively mitigate and manage risk of critical network infrastructure in a changing environment.
            </p>
          </div>
          <div className="col-md-6 py-2 px-md-5">
            <h3 className="h4">Trusted Brand</h3>
            <p>
            Irth has been the top provider for 811 (one call) ticket management and utility locating software since 1995.
            </p>
          </div>
          {/* <div className="col-md-4 py-2 px-md-5">
            <h3 className="h4">Support</h3>
            <p>
              Configuration & customization
              <br />
              24/7 Email & Phone Support
              <br />
              Training
              <br />
              Certification available
              <br />
              Paperless Environment
              <br />
              Don't have to carry papers, go green
            </p>
          </div>
          <div className="col-md-4 py-2 px-md-5">
            <h3 className="h4">Error Reduction</h3>
            <p>Minimal chances of you making errors.</p>
          </div>
          <div className="col-md-4 py-2 px-md-5">
            <h3 className="h4">Training</h3>
            <p>
              You can be up and running with our software in as little as two
              weeks and in full production within one month.
            </p>
          </div> */}
        </div>
        <div className="row text-center">
          <div className="col-md-6 mb-5">
            <a
              className="btn-primary"
              href="https://www.irthsolutions.com/infrastructure-protection/"
              target="_blank"
              rel="noreferrer noopener"
            >
              Infrastructire Protection
            </a>
          </div>
          <div className="col-md-6">
            <a
              className="btn-primary"
              href="https://www.irthsolutions.com/land-management/"
              target="_blank"
              rel="noreferrer"
            >
              Land Management Software
            </a>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default LandManagementSystem
